/** @jsx jsx */
import { jsx } from 'theme-ui';
import { H3, H4, P } from '../../components/Text';
import { AccessibilityLayout } from '../../layouts/AccessibilityLayout';

const AccessibilityPage = props => {
  return (
    <AccessibilityLayout>
      <P>La Interaction Design Association s'engage à créer des événements inclusifs et accueillants pour tous les participants. Nous croyons que le design inclusif consiste à retirer les obstacles à l'accès. Sur le Web, cela représente l'accès à l'information.      </P>
      <P>Cette année, nous avons vu grand. Global. Nous rendons Interaction21 accessible à de tous nouveaux publics des manières suivantes.</P>
      <ul sx={{ml: 5}}>
        <li><P><span sx={{fontWeight: "bold"}}>Programme 1:1:</span> Les conférences coûtent cher. Cette année, nous avons créé un programme 1:1 pour donner accès aux participants qui, autrement, ne seraient pas en mesure d'assumer le coût du billet. Pour chaque billet vendu à tarif régulier, nous en avons offert un à quelqu'un qui n'en avait pas les moyens.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>24h de contenu:</span>  Notre programme quotidien de 8 heures sera re-diffusé pour s'adapter à 3 fuseaux horaires mondiaux afin que les participants du monde entier puissent y assister et socialiser à l'heure qui leur convient le mieux.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Sous-titrage en direct pour les sourds ou malentendants:</span> Nous nous sommes associés à ACS afin de pouvoir fournir du sous-titrage en direct pour toutes nos sessions.</P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Traduction en direct:</span> Pour accommoder les présentateurs qui ne se seraient pas à l'aise de s'exprimer en anglais, leurs sessions seront sous-titrées en anglais. </P></li>
        <li><P><span sx={{fontWeight: "bold"}}>Couleurs du site Web:</span> Le thème de cette année, "Concevoir Dans Des Temps Périlleux", et l'identité visuelle ont été intentionnellement conçus pour être inconfortables. Cependant, les couleurs à contraste élevé peuvent être inconfortables pour les yeux lorsqu'affichées sur des écrans. C'est pourquoi nous avons fourni un sélecteur de mode sombre facilement accessible sur les pages de notre site Web sur tous les appareils. </P></li>
      </ul>
      <H4>Pour plus d'informations</H4>
      <P>
        Si vous avez des demandes à nous faire parvenir ou si vous voulez nous
        informer de tout problème d'accessibilité, veuillez nous contacter à{' '}
        <a
          href="mailto:interaction21@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          interaction21@ixda.org
        </a>
        .
      </P>
    </AccessibilityLayout>
  );
};


export default AccessibilityPage;
